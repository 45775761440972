import * as React from "react";
import "./src/styles/global.css";
import "@fontsource/raleway";
import "@fontsource/raleway/600.css";
import "@fontsource/raleway/800.css";
import "@fontsource/ibm-plex-mono";
import "@fontsource/ibm-plex-mono/200.css";
import "@fontsource/ibm-plex-mono/500.css";
import "@fontsource/ibm-plex-mono/700.css";
import Theme from "./src/components/Theme";

export const wrapRootElement = ({ element }) => <Theme>{element}</Theme>;
