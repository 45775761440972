
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const styled_components_1 = require("styled-components");

const brightTheme = {
    backgroundColor: 'white',
};
const darkTheme = {...brightTheme, backgroundColor: 'grey'};
function Theme({ children }) {
    const [colorMode, setColorMode] = (0, react_1.useState)('bright');
    return ((0, jsx_runtime_1.jsx)(styled_components_1.ThemeProvider, {theme: colorMode === 'bright' ? brightTheme : darkTheme, children}, void 0));
}
exports.default = Theme;
// # sourceMappingURL=Theme.js.map